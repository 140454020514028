import { useState } from "react"
import {
  Form,
  FormTitle,
  FormControl,
  FormInput,
  FormLabel,
  FormBtn,
  FormTextArea,
  ResponseMessage,
  InputError,
} from "./Contact.styled"

const ContactForm = () => {
  const initialValues = { fullName: "", email: "", phone: "", message: "" }
  const [formData, setFormData] = useState(initialValues)
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  })
  const [sendBtn, setSendBtn] = useState("Send")
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => {
      return { ...prevFormData, [name]: value }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const validate = ({ fullName, email, phone, message }) => {
      let isValid = false
      if (fullName.trim().length < 2) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            fullName: "Name Must Be At Least 2 Characters!",
          }
        })
        isValid = false
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            fullName: "",
          }
        })
        isValid = true
      }

      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!regex.test(email)) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            email: "Please Enter Valid Email!",
          }
        })
        isValid = false
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            email: "",
          }
        })
        isValid = true
      }

      if (phone.trim().length < 10) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            phone: "Please Enter Valid Phone Number!",
          }
        })
        isValid = false
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            phone: "",
          }
        })
        isValid = true
      }

      if (message.trim().length < 2) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            message: "Message Must Be At Least 4 Characters!",
          }
        })
        isValid = false
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            message: "",
          }
        })
        isValid = true
      }

      return isValid
    }
    let validationResults = validate(formData)

    if (validationResults) {
      setSendBtn("Sending..")
      const newEntry = {
        customerName: formData.fullName,
        customerEmail: formData.email,
        customerPhoneNumber: formData.phone,
        customerMessage: formData.message,
      }
      const endpoint =
        "https://51t60p8hs5.execute-api.us-east-1.amazonaws.com/default/sendContactEmail"
      const requestOptions = {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newEntry),
      }
      fetch(endpoint, requestOptions)
        .then((response) => {
          if (!response.ok) throw new Error("Error in fetch")
          return response.json()
        })
        .then((response) => {
          setSuccessMessage("Message was delivered successfully!")
          setSendBtn("Send")
        })
        .catch((error) => {
          setErrorMessage("An unknown problem occured. Try again.")
        })
      setFormData(initialValues)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormTitle>Or Send Us A Message</FormTitle>
      <FormControl>
        <FormLabel>Your Name</FormLabel>
        <FormInput
          type="text"
          placeholder="Enter your name"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
        />
        <InputError>{errors.fullName}</InputError>
      </FormControl>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <FormInput
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <InputError>{errors.email}</InputError>
      </FormControl>
      <FormControl>
        <FormLabel>Phone Number</FormLabel>
        <FormInput
          type="text"
          placeholder="Enter Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        <InputError>{errors.phone}</InputError>
      </FormControl>
      <FormControl>
        <FormLabel>Message</FormLabel>
        <FormTextArea
          placeholder="Enter your message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        <InputError>{errors.message}</InputError>
      </FormControl>
      <FormBtn>{sendBtn}</FormBtn>
      <ResponseMessage>{successMessage}</ResponseMessage>
      <ResponseMessage errorMessage={errorMessage}>
        {errorMessage}
      </ResponseMessage>
    </Form>
  )
}
export default ContactForm
