import { Routes, Route, useLocation } from "react-router-dom"
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"
import Home from "./pages/Home/Home"
import About from "./pages/About/About"
import Services from "./pages/Services/Services"
import Contact from "./pages/Contact/Contact"
import { AnimatePresence } from "framer-motion"
import theme from "./theme"
import { GlobalStyle, Container } from "./Global/Global.styled"
import { ThemeProvider } from "styled-components"

function App() {
  const location = useLocation()
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Container>
          <Header />
          <AnimatePresence>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/Services" element={<Services />} />
              <Route path="/Contact" element={<Contact />} />
            </Routes>
          </AnimatePresence>
          <Footer />
        </Container>
      </ThemeProvider>
    </>
  )
}

export default App
