import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    font-family: 'Inter', sans-serif;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
  button,
  input,
  textarea {
    font-family: 'Inter', sans-serif;
  }
`

export const Container = styled.div`
  background-color: white;
  width: 100%;
  position: relative;
`

export const Main = styled.main`
  padding: ${({ theme }) => theme.headerHeight} 0.5rem 0 0.5rem;
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  position: relative;
`
