import styled from "styled-components"
import { FaBars, FaWindowClose } from "react-icons/fa"

export const HeaderBar = styled.header`
  background-color: black;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: grid;
`

export const HeaderTop = styled.div`
  display: flex;
  height: 3rem;
  justify-content: space-around;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 2.75rem;
    padding: 0 0.75rem;
    border-radius: 1.25rem;

    background-color: ${({ theme }) => theme.orange};
    color: white;
    font-weight: bold;

    img {
      height: 2rem;
    }
  }
`

export const MobileNavBtn = styled.button`
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  color: white;
  display: none;
  place-items: center;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`

export const BarsIcon = styled(FaBars)`
  font-size: inherit;
  color: inherit;
`

export const CloseIcon = styled(FaWindowClose)`
  font-size: inherit;
  color: inherit;
`

export const NavLogoBox = styled.div`
  height: inherit;

  a {
    display: grid;
    place-items: center;
    height: 3rem;
    background-color: transparent;

    img {
      height: inherit;
      background: transparent;
    }
  }
`

export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.yellow};
  height: ${({ mobileNav }) => (mobileNav ? "100vh" : "3rem")};
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 0 0.5rem;

  @media screen and (max-width: 768px) {
    display: ${({ mobileNav }) => (mobileNav ? "grid" : "none")};
  }
`

export const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 0.75rem;

  li {
    a {
      color: ${({ theme }) => theme.text};
      display: grid;
      place-items: center;

      &.active {
        border-bottom: 2px solid ${({ theme }) => theme.orange};
      }

      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.orange};
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: grid;

    li {
      a {
        font-size: 2rem;
        font-weight: bold;
      }
    }
  }
`
