const theme = {
  bg: "white",
  text: "rgb(0,0,0)",
  yellow: "#ffd100",
  orange: "orangered",
  headerHeight: "6rem",
  maxWidth: "1400px",
}

export default theme
