import {
  HeaderTop,
  Nav,
  NavList,
  NavLogoBox,
  MobileNavBtn,
  BarsIcon,
  CloseIcon,
  HeaderBar,
} from "./Header.styled"
import { NavLink, Outlet } from "react-router-dom"
import logo from "../../assests/svgs/srelogo3.svg"
import phoneIcon from "../../assests/svgs/phone-icon.svg"
import { useState } from "react"

const Header = () => {
  const [mobileNav, setMobileNav] = useState(false)

  function toggleMobileNav() {
    setMobileNav((prev) => !prev)
  }

  return (
    <>
      <HeaderBar mobileNav={mobileNav}>
        <HeaderTop>
          <NavLogoBox className="logobox">
            <NavLink to="/" onClick={() => setMobileNav(false)}>
              <img src={logo} alt="sre logo" />
            </NavLink>
          </NavLogoBox>
          <a href="tel:4342008678">
            {" "}
            <img src={phoneIcon} alt="" />
            (434) 200-8678
          </a>
          <MobileNavBtn onClick={toggleMobileNav}>
            {mobileNav ? <CloseIcon /> : <BarsIcon />}
          </MobileNavBtn>
        </HeaderTop>
        <Nav mobileNav={mobileNav}>
          <NavList mobileNav={mobileNav}>
            <li>
              <NavLink to="/" onClick={() => setMobileNav(false)}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" onClick={() => setMobileNav(false)}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/services" onClick={() => setMobileNav(false)}>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={() => setMobileNav(false)}>
                Contact
              </NavLink>
            </li>
          </NavList>
        </Nav>
        <Outlet />
      </HeaderBar>
    </>
  )
}
export default Header
