import { Main } from "../../Global/Global.styled"
import { Link } from "react-router-dom"
import {
  HomeBtnWrapper,
  HomeIntro,
  HomeSection,
  HomeServicesList,
} from "./Home.styled"
import commercialIcon from "../../assests/svgs/commercial-icon.svg"
import retailIcon from "../../assests/svgs/shopping-cart-icon.svg"
import houseIcon from "../../assests/svgs/house-icon.svg"
import generatorIcon from "../../assests/svgs/generator.svg"

const Home = () => {
  return (
    <Main>
      <HomeSection>
        <HomeIntro>
          <h1>SOUTHERN RIDGE ELECTRIC</h1>
          <h2>Residential & Commercial Class A Contractor</h2>
          <p>Serving the Virginia and North Carolina community</p>
          <HomeBtnWrapper>
            <Link to="/contact">Get Quote</Link>
          </HomeBtnWrapper>
        </HomeIntro>
        <HomeServicesList>
          <li>
            <img src={houseIcon} alt="House Icon" />
            <h3>Residential Electrical Services</h3>
            <Link to="/services">Learn More</Link>
          </li>
          <li>
            <img src={retailIcon} alt="Retail Icon" />
            <h3>Retail Electrical Services</h3>
            <Link to="/services">Learn More</Link>
          </li>
          <li>
            <img src={commercialIcon} alt="Commercial Icon" />
            <h3>Commercial Electrical Services</h3>
            <Link to="/services">Learn More</Link>
          </li>
          <li>
            <img src={generatorIcon} alt="Generator Icon" />
            <h3>Generators Electrical Services</h3>
            <Link to="/services">Learn More</Link>
          </li>
        </HomeServicesList>
      </HomeSection>
    </Main>
  )
}
export default Home
