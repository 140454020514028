import styled from "styled-components"

export const HomeSection = styled.section`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-gap: 0.5rem;
  align-items: end;
`

export const HomeIntro = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  align-content: center;
  place-content: center;

  @media screen and (max-width: 700px) {
    h1 {
      text-align: center;
    }
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  h1 {
    font-size: clamp(1.5rem, 4vw, 4rem);
  }

  h2 {
    font-size: clamp(1.25rem, 3vw, 2rem);
  }

  p {
    color: rgb(20, 20, 20);
  }
`

export const HomeBtnWrapper = styled.div`
  display: flex;
  gap: 1rem;

  a {
    display: block;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.orange};
    color: white;
    font-weight: bold;
  }
  @media screen and (max-width: 500px) {
    a {
      width: 100%;
    }
  }
`

export const HomeServicesList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  place-content: center;

  li {
    background-color: ${({ theme }) => theme.yellow};
    border-radius: 0.5rem;
    display: grid;
    grid-gap: 0.25rem;
    place-items: center;
    align-content: center;
    height: 10rem;

    h3 {
      color: black;
      text-align: center;
    }

    img {
      height: 3rem;
    }

    a {
      font-weight: bold;
      height: 2rem;
      padding: 0 0.5rem;
      border-radius: 0.25rem;
      display: grid;
      place-items: center;
      color: white;
      background-color: orangered;
    }
  }
`
