import { Main } from "../../Global/Global.styled"
import ContactForm from "./ContactForm"
import {
  ContactHeading,
  ContactSection,
  ContactWrapper,
  ContactInfo,
} from "./Contact.styled"
import phoneIcon from "../../assests/svgs/phone-icon.svg"

const Contact = () => {
  return (
    <Main>
      <ContactSection>
        <ContactHeading>Contact Us</ContactHeading>
        <ContactWrapper>
          <ContactInfo>
            <h3>Give Us A Call</h3>
            <a href="tel:4342008678" target="_blank" rel="noreferrer noopener">
              <img src={phoneIcon} alt="Phone Icon" />
              (434) 200-8678
            </a>
            <h3>Follow Us</h3>
            <a
              href="https://www.facebook.com/pages/category/Electrician/Southern-Ridge-Electric-111104237389754/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Facebook
            </a>
            <a
              href="https://nextdoor.com/pages/southern-ridge-electric-callands-va/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Nextdoor
            </a>
          </ContactInfo>
          <ContactForm />
        </ContactWrapper>
      </ContactSection>
    </Main>
  )
}
export default Contact
