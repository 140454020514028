import { Main } from "../../Global/Global.styled"
import {
  ServicesSection,
  ServicesList,
  ServiceItem,
  ServicesIntro,
  ServicesSubList,
} from "./Services.styled"
import { Link } from "react-router-dom"

const Services = () => {
  return (
    <Main>
      <ServicesSection>
        <h2>Our Services</h2>
        <ServicesIntro>
          At Southern Ridge Electric, LLC, we know that our commercial and
          residential clients want their experience to be professional and
          efficient. They also require a wide selection of electrical services
          from us. To meet these needs, we are proud to provide our customers
          with the following services:
        </ServicesIntro>
        <ServicesList>
          <ServiceItem>
            <h3>Residential</h3>
            <p>Single Family Homes | New Construction | Condos | Townhouses</p>
            <ServicesSubList>
              <li>Bad Wiring Corrections</li>
              <li>Bathroom Fans & Ceiling Fans</li>
              <li>Cable TV / Phone / Data outlets</li>
              <li>Chandeliers</li>
              <li>Code Violation Corrections</li>
              <li>Dimmer Switches</li>
              <li>Electrical Service Upgrades</li>
              <li>Fuse Box Replacement</li>
              <li>Lighting Design</li>
              <li>New Site Construction & Renovations</li>
              <li>Outlets & Switches</li>
              <li>Over/Under Cabinet Lighting</li>
              <li>Recessed Lighting</li>
              <li>Replace Circuit Breakers</li>
              <li>Smoke & Carbon Monoxide Detectors</li>
              <li>Troubleshooting</li>
              <li>Yard Lights</li>
            </ServicesSubList>
            <Link to="/contact">Request Quote</Link>
          </ServiceItem>
          <ServiceItem>
            <h3>Retail</h3>
            <p>Restaurants | Small Shops | Salons | Cafes</p>
            <ServicesSubList>
              <li>Bad Wiring Connections</li>
              <li>Bathroom Hand Dryers</li>
              <li>Cable TV / Phone / Data outlets</li>
              <li>Ceiling Fans</li>
              <li>Chandeliers</li>
              <li>Code Violation Corrections</li>
              <li>Electrical Service Upgrades</li>
              <li>Emergency Signs & Lights</li>
              <li>Lighting Design</li>
              <li>Motion Sensors</li>
              <li>New Site Construction & Renovations</li>
              <li>Outdoor Lighting</li>
              <li>Outlets & Switches</li>
              <li>Recessed Lighting</li>
              <li>Smoke & Carbon Monoxide Detectors</li>
              <li>Troubleshooting</li>
            </ServicesSubList>
            <Link to="/contact">Request Quote</Link>
          </ServiceItem>
          <ServiceItem>
            <h3>Commercial</h3>
            <p>Office Buildings | Large Retail | Warehouses | Gas Stations</p>
            <ServicesSubList>
              <li>Bathroom Hand Dryers</li>
              <li>Cable TV / Phone / Data outlets</li>
              <li>Code Violation Corrections</li>
              <li>Electrical Service Upgrades</li>
              <li>Emergency Lighting Set Up</li>
              <li>Equipment Testing & Replacement</li>
              <li>Indoor & Outdoor Lighting</li>
              <li>Lighting Systems</li>
              <li>Motion Sensors</li>
              <li>New Site Construction & Renovations</li>
              <li>Phone & Data outlets</li>
              <li>Pole lights</li>
              <li>Service upgrades</li>
              <li>Service & Repair</li>
              <li>Smoke & Carbon Monoxide Detectors</li>
              <li>Troubleshooting</li>
            </ServicesSubList>
            <Link to="/contact">Request Quote</Link>
          </ServiceItem>
          <ServiceItem>
            <h3>Backup Generators</h3>
            <p>
              Residential | Retail | Warehouses | Restaurants | Office Building
            </p>
            <ServicesSubList>
              <li>Partial backup system on a dwelling or business</li>
              <li>
                Install Interlocking kit on main panel with outside receptacle
              </li>
              <li>
                Install Interlocking kit on main panel with outside receptacle
              </li>
              <li>Install manual transfer switch with outside receptacle</li>
              <li>
                Install whole house or small business Generac generators with
                automatic transfer switch:
              </li>
              <li>
                Install 200amp automatic transfer switch to run whole house or
                small business
              </li>
              <li>
                Install generators up to 150KVA to power a larger business with
                many electrical needs.
              </li>
            </ServicesSubList>
            <Link to="/contact">Request Quote</Link>
          </ServiceItem>
        </ServicesList>
      </ServicesSection>
    </Main>
  )
}
export default Services
