import { MainFooter } from "./Footer.styled"

const Footer = () => {
  const footerYear = new Date().getFullYear()
  return (
    <MainFooter>
      <p>
        Copyright &copy; {footerYear} Southern Ridge Electric. All Rights
        Reserved
      </p>
    </MainFooter>
  )
}
export default Footer
