import styled from "styled-components"

export const MainFooter = styled.footer`
  width: 100%;
  height: 4rem;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;

  p {
    text-align: center;
  }
`
