import { Main } from "../../Global/Global.styled"
import { AboutSection } from "./About.styled"

const About = () => {
  return (
    <Main>
      <AboutSection>
        <h2>Our Company:</h2>
        <p>
          Southern Ridge Electric, LLC has been serving south central Virginia
          and the northern piedmont of North Carolina since 2020. With over 15
          years’ experience in the electrical field. With our knowledge and
          experience, we serve our clients by delivering superior workmanship
          for projects that are on time and on budget and we strive to give our
          clients the highest quality electrical services at the best market
          price. From our first meeting to the end of your project, it’s our
          pledge & goal to meet the needs & expectations of our clients.
        </p>
        <p>
          Although our projects may vary, our commitment to our clients remains
          constant. We collaboratively plan each phase and believe that
          communication is the key to project success. Southern Ridge Electric,
          LLC is licensed & fully insured in the state of Virginia and North
          Carolina.
        </p>
        <h3>Superior Quality</h3>
        <p>
          Southern Ridge Electric, LLC believes that a quality job begins with
          quality people, so we employ highly trained full-timed tradesmen. Our
          entire team strives to provide the highest quality work along with
          professional, reliable services. We take pride in making customers
          exceptionally pleased on each and every job.
        </p>
        <h3>Planning</h3>
        <p>
          We recognize the importance of proper planning and staging on
          projects. This saves you money, produces a better end product and
          makes for a smooth running job site. We work directly with you to plan
          the job from the start. We are thinking three steps ahead. Our
          professional estimating, project managing, and production experience
          is you guarantee that the project is completed on time, with
          uncompromising quality.
        </p>
      </AboutSection>
    </Main>
  )
}
export default About
