import styled from "styled-components"

export const ContactSection = styled.section`
  height: 100%;
  width: 100%;
  max-width: 1000px;
  display: grid;
  align-content: center;
`

export const ContactHeading = styled.h2`
  width: 100%;
  text-align: center;
  font-size: clamp(1.5rem, 3vw, 3rem);
`

export const ContactWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));
  background-color: black;
  border-radius: 0.25rem;
`
export const ContactInfo = styled.div`
  display: grid;
  align-content: center;
  grid-gap: 1rem;

  h3 {
    text-align: center;
    color: white;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 2.5rem;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.orange};
    color: white;
    font-weight: bold;

    img {
      height: 2rem;
    }
  }
`
export const Form = styled.form`
  display: grid;
  grid-gap: 1rem;
`

export const FormTitle = styled.h3`
  text-align: center;
  color: white;
`
export const FormControl = styled.div`
  display: grid;

  small {
    text-align: center;
  }
`
export const FormLabel = styled.label`
  display: grid;
  color: white;
`
export const FormInput = styled.input`
  display: block;
  height: 2rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-indent: 2px;
`
export const FormTextArea = styled.textarea`
  display: block;
  min-height: 4rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 2px;
`
export const InputError = styled.small`
  color: red;
`

export const ResponseMessage = styled.p`
  color: ${({ errorMessage }) => (errorMessage ? "red" : "green")};
`
export const FormBtn = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.orange};
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  height: 2rem;
  border-radius: 0.25rem;
  cursor: pointer;
`
