import styled from "styled-components"

export const ServicesSection = styled.section`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  display: grid;
  place-items: center;
  grid-gap: 1rem;

  h2 {
    text-align: center;
  }
`

export const ServicesIntro = styled.p`
  max-width: 800px;
`

export const ServicesList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(359px, 1fr));
  grid-gap: 1rem;
  justify-content: center;
`
export const ServiceItem = styled.li`
  padding: 1rem;
  background-color: ${({ theme }) => theme.yellow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.25rem;

  h3 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  a {
    background-color: ${({ theme }) => theme.orange};
    color: white;
    font-weight: bold;
    display: grid;
    place-items: center;
    height: 2rem;
    border-radius: 0.25rem;
  }
`

export const ServicesSubList = styled.ul`
  flex: 1;
  display: grid;
  grid-gap: 0.25rem;
  list-style-position: inside;
  list-style-type: square;
`
