import styled from "styled-components"

export const AboutSection = styled.section`
  height: 100%;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  display: grid;
  place-content: center;
  grid-gap: 1rem;

  p {
    max-width: 800px;
  }
`
